.Button{
    cursor: pointer;
    border: none;
    height: 3em;
    border-radius: 20px;
    padding: 4px 12px;
    font-size: .5em;
}

.Button:hover {
    animation: scale-button-action 1s ease-in-out;
}
  

@keyframes scale-button-action {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
      box-shadow: var(--box-shadow);
    }
    100% {
        transform: scale(1);
      }
  }
  