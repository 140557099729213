html, body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .text-container {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 96px;
    color: white;
    opacity: 0.8;
    user-select: none;
    text-shadow: 1px 1px rgba(0,0,0,0.1);
  }
  
  :root {
    --color-teste: rgb(254, 255, 222);
    --color1: rgb(250, 254, 217);
    --color2: rgb(255, 255, 255);
    --color3: rgb(249, 255, 234);
    --color4: rgb(253, 255, 241);
    --color5: rgb(246, 255, 215);


    --color-bg1: rgb(255, 255, 255);
    --color-bg2: rgb(238, 238, 238);
    --color1: 255, 255, 255;
    --color2: 255, 255, 255;
    --color3: 249, 255, 234;
    --color4: 253, 255, 241;
    --color5: 246, 255, 215;
    --color-interactive: 254, 255, 222;
    --circle-size: 80%;
    --blending: hard-ligh;
  }
  
  @keyframes moveInCircle {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes moveVertical {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  
  @keyframes moveHorizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }
    50% {
      transform: translateX(50%) translateY(10%);
    }
    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }
  
  
  .gradient-bg {
    position: fixed !important;
    top: 0 ;
    left: 0 ;
    z-index: -1 ;  // Para garantir que fique atrás do conteúdo
    width: 100vw !important;
    height: 100vh !important;
    position: relative;
    overflow: hidden;
    background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
    top: 0;
    left: 0;
  
    svg {
      display: none;
    }
  
  
    .gradients-container {
      position: fixed !important;
      top: 0;
      left: 0;
      z-index: -1; 
      filter: url(#goo) blur(40px) ;
      width: 100vw;
      height: 100vh;;
    }
  
    .g1 {
      position: absolute;
      background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
      mix-blend-mode: var(--blending);
  
      width: var(--circle-size);
      height: var(--circle-size);
      top: calc(50% - var(--circle-size) / 2);
      left: calc(50% - var(--circle-size) / 2);
  
      transform-origin: center center;
      animation: moveVertical 30s ease infinite;
  
      opacity: 1;
    }
  
    .g2 {
      position: absolute;
      background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
      mix-blend-mode: var(--blending);
  
      width: var(--circle-size);
      height: var(--circle-size);
      top: calc(50% - var(--circle-size) / 2);
      left: calc(50% - var(--circle-size) / 2);
  
      transform-origin: calc(50% - 400px);
      animation: moveInCircle 20s reverse infinite;
  
      opacity: 1;
    }
  
    .g3 {
      position: absolute;
      background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
      mix-blend-mode: var(--blending);
  
      width: var(--circle-size);
      height: var(--circle-size);
      top: calc(50% - var(--circle-size) / 2 + 200px);
      left: calc(50% - var(--circle-size) / 2 - 500px);
  
      transform-origin: calc(50% + 400px);
      animation: moveInCircle 40s linear infinite;
  
      opacity: 1;
    }
  
    .g4 {
      position: absolute;
      background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
      mix-blend-mode: var(--blending);
  
      width: var(--circle-size);
      height: var(--circle-size);
      top: calc(50% - var(--circle-size) / 2);
      left: calc(50% - var(--circle-size) / 2);
  
      transform-origin: calc(50% - 200px);
      animation: moveHorizontal 40s ease infinite;
  
      opacity: 0.7;
    }
  
    .g5 {
      position: absolute;
      background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
      mix-blend-mode: var(--blending);
  
      width: calc(var(--circle-size) * 2);
      height: calc(var(--circle-size) * 2);
      top: calc(50% - var(--circle-size));
      left: calc(50% - var(--circle-size));
  
      transform-origin: calc(50% - 800px) calc(50% + 200px);
      animation: moveInCircle 20s ease infinite;
  
      opacity: 1;
    }
  
    .interactive {
      position: absolute;
      background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
      mix-blend-mode: var(--blending);
  
      width: 100%;
      height: 100%;
      top: -50%;
      left: -50%;
  
      opacity: 0.7;
    }
  }