#Serviços {
  padding: 1em 2em;
  display: flex;
  flex-direction: row;
}
.cards-main {
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

@media (max-width: 960px) {
  #Serviços {
    flex-direction: column-reverse;
    gap: 32px;
    padding-top: 2em;
  }
  .cards-main {
    padding: 0px;
    width: 90%;
    justify-content: center;
  }
}
