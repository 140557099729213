.CallToAction {
  min-width: 35%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: transparent;
}

.CallToAction h1 {
  font-size: 2.5rem;
  font-weight: 800;
  background-color: transparent;
}
.CallToAction p {
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
}

@media (max-width: 960px) {
  .CallToAction {
    border-radius: 8px;
    align-items: center !important;
    text-align: center !important;
    max-width: 80%;
    padding: 16px;
    margin-top: 3rem;
    backdrop-filter: blur(15px);
    border: none;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  }

  #CallToAction2 {
    backdrop-filter: none;
    box-shadow: none;
    padding: 0;
  }
  #CallToAction3 p {
    color: var(--secondary);
  }
}
