@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  font-family: montserrat;
}

body {
  overflow-x: hidden;
}

:root {
  --primary: #ffffff;
  --secundary: #000000;
  --secundary-2: #00000008;
  --tertiary: #f9e745;
  --quaternary: #e1347f;
  --quinary: #46c8e0;
  --box-shadow: 0 0 1.5rem rgba(0 0 0 / 0.1);
}

.App {
  font-family: montserrat;
  display: flex;
  flex-direction: column;
}

#arrow-container {
  position: fixed;
  bottom: 0;
  width: 100%;
}

#arrow-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
}

#arrow:hover {
  cursor: pointer;
  animation: scale 1s ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
