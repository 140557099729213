#Casos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.Title{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cases{
    width: 60%;
}

.carousel-container{
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: baseline;
    width: 80%;
    height: 100%;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 0.8rem rgba(0 0 0 / 0.1);
    cursor: pointer;
    border: 1px solid rgb(226, 226, 226);
}

.card-image {
    width: 100%;
    height: 16vh;
    overflow: hidden;
    border-radius: 8px;
}

.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
