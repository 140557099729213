.contact-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 50%;
}
#Avaliações{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.assessments-list{
    width: 70%;
}

.card-item-assessments {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: baseline;
    padding: 0.2em;
    width: 90%;
    height: 100%;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
  }

  
.card-text h3 {
    font-size: 16px;
    font-weight: 600;
  }
  .card-text p {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    text-align: left;
    margin: 4px;
  }
  
  .card-person {
    display: flex;
    flex-direction: row;
    gap: 0.2em;
    height: auto;
    align-items: flex-start;
  }
  
  .card-person-rating {
    display: flex;
    flex-direction: row;
  }
  
  .card-person-rating-star {
    position: relative;
    margin-right: 12px;
  }
  
  .card-person-rating-star > * {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .card-person-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  
  .card-person img {
    width: 32px;
    height: 32px;
  }
  
  .card-person h3 {
    font-size: 15px;
  }
  
  .card-person-rating {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-start;
  }
  