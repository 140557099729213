.ServiceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  padding: 8px;
  background: none;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(134, 134, 134, 0.25);
  box-shadow: 0 0 20px rgba(108, 105, 128, 0.25);
}
.ServiceCard h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.ServiceCard p {
  font-size: 12px;
  font-weight: 300;
  text-transform: lowercase;
}
.ServiceCard ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  text-align: start;
  list-style-type: none;
  font-size: 12px;
  font-weight: 400;
  text-transform: lowercase;
}

.ServiceCard li {
  padding: 4px;
  border-radius: 4px;
  text-transform: none;
}

@media (max-width: 960px) {
  .ServiceCard {
    width: 60% !important;
  }
}
