.Contact {
  border-radius: 0.8em;
  padding: 0.5em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4em;
  box-shadow: var(--box-shadow);
  width: 100%;
}
.Contact:hover {
  animation: scale-button-contact 1s ease-in-out;
}

.Contact i {
  display: flex;
}

.Contact-Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.Contact-Title {
  font-size: 0.8em;
}

.Contact-Value {
  font-size: 0.7em;
  text-transform: lowercase;
}

@keyframes scale-button-contact {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
    color: var(--primary);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 960px) {
  .Contact {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    width: 120%;
    padding: 0.5rem;
  }
}

@media (max-width: 500px) {
  .Contact {
    font-size: 60%;
  }
} 
