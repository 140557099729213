#doubts-container {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

#Dúvidas img {
  height: 70vh;
}

@media (max-width: 960px) {
  #doubts-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 0;
  }
  #Dúvidas img {
    height: 50vh;
  }
}
