.Header {
  height: 3.5rem;
  background: none;
  z-index: 1;
}

.Header ul {
  list-style: none;
}

.Header a {
  text-decoration: none;
  color: var(--secondary);
  transition: color 0.3s ease-in-out;
}

.Header a:hover,
a.active {
  color: var(--quaternary);
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  border-radius: 8px;
}

.container {
  max-width: 1170px;
  padding: 16px;
  margin-inline: auto;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  color: var(--secondary);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-bar {
  display: flex;
  flex-direction: row;
  gap: 0.8em;
}

.menu-bar-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8em;
}

.menu-bar-elements p {
  font-size: 0.5em;
  font-weight: 100;
}

.menu-bar li a {
  text-transform: capitalize;
  font-size: 16px;
}

.mobile-menu-bar {
  display: none;
}

.mobile-menu-bar select {
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  background: transparent;
  color: var(--quaternary);
}

.nameLogo {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 960px) {
  .mobile-menu-bar {
    display: flex;
  }
  .menu-bar {
    display: none;
  }
}
