.section {
  color: var(--secundary);
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}