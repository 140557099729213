.Case {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-size: 100%;
}

.Case-cover {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--tertiary);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Para cobrir toda a extensão do elemento */
}

.Case-cover-text {
  text-align: center;
  color: var(--primary);
  backdrop-filter: blur(20px);
  border: 1px solid rgb(176, 176, 176);
  border-radius: 0.5em;
  object-fit: cover;
  padding: 0.5em;
}

.Case-Data {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 5vh 20vh;
  height: auto;
}

.Case-Data-Text {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.Case-Action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  font-size: 200%;
  margin-bottom: 2em;
  color: rgb(125, 125, 125);
  width: 100%;
  text-align: center;
}

@media (max-width: 960px) {
  .Case-Data {
    margin: 6vh 1rem;
  }
}

@media (max-width: 650px) {
  .Case-cover-text {
    margin-top: 2vh;
  }
  .Case-cover {
    height: 23vh;
  }
}

@media (max-width: 500px) {
  .Case-cover-text {
    margin-top: 3vh;
  }
  .Case-cover {
    height: 25vh;
  }
}

@media (max-width: 400px) {
  .Case-cover-text h1 {
    font-size: 150%;
  }
  .Case-cover {
    height: 30vh;
  }
}
